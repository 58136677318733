import React, { useRef, useState } from 'react';
import TextInput from './TextInput';
import useDisableNumberInputScroll from './useDisableNumberInputScroll';



const Step1 = () =>
{


    useDisableNumberInputScroll();


    return (

        <div className="right">
            <div className='main-scroll'>


                <TextInput type="text" field="name" placeholder="Enter your name" label="Name: *" />
                <TextInput type="text" field="membershipId" placeholder="Enter your ID Number" label="ID Number: " />
                <TextInput type="number" field="whatsappNo" placeholder="Enter your Whatsapp Number" label="Whatsapp Number: *" />




                <div style={ { marginBottom: '10px' } } ></div>
            </div>
        </div>
    );
};

export default Step1;
