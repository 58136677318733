import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Payments from './Payment';
import exampleImage from './../../assets/1940_250.jpg'; // Adjust the path according to your folder structure
import { useCommonContext } from '../Contexts/CommonContext';
import { useAppStateContext } from './Reducers/AppStateContext';


const InternForm = () =>
{
    const { state } = useCommonContext();
    const { dropdowns } = useAppStateContext();

    const navigate = useNavigate();

    const [ currentComponent, setCurrentComponent ] = useState( 1 );

    // When clicking "Next"
    const nextClicked = () =>
    {
        setCurrentComponent( ( prevComponent ) =>
        {
            // Move to the next component
            let nextComponent = ( prevComponent % 5 ) + 1;

            // Skip component 4 if dataTick is true
            if ( dropdowns.membershipTicket == "" && nextComponent === 4 )
            {
                nextComponent = 5; // Skip to component 5
            }

            return nextComponent;
        } );
    };

    // When clicking "Previous"
    const prevClicked = () =>
    {
        setCurrentComponent( ( prevComponent ) =>
        {
            // Move to the previous component
            let previousComponent = ( prevComponent - 2 + 5 ) % 5 + 1;

            // Skip component 4 if dataTick is true
            if ( dropdowns.membershipTicket == "" && previousComponent === 4 )
            {
                previousComponent = 3; // Skip back to component 3
            }

            return previousComponent;
        } );
    };


    const pageChangeClick = ( index ) =>
    {
        setCurrentComponent( index );
    };


    const renderCurrentComponent = () =>
    {
        switch ( currentComponent )
        {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Payments />;
            default:
                return null;
        }
    };

    const [ isMobile, setIsMobile ] = useState( window.innerWidth <= 768 );

    useEffect( () =>
    {
        const handleResize = () =>
        {
            setIsMobile( window.innerWidth <= 768 );
        };

        window.addEventListener( 'resize', handleResize );

        return () => window.removeEventListener( 'resize', handleResize );
    }, [] );

    const imageStyle = {
        width: isMobile ? '266px' : '400px',  // Width based on screen size
        height: isMobile ? '50px' : '75px',  // Height based on screen size
    };


    return (
        <section>
            <div className="container" style={ { height: '100vh' } }>
                <div className="main">

                    <div className="main-div">
                        <div className="left">
                            <div onClick={ () => pageChangeClick( 1 ) } className={ currentComponent == 1 ? "sec active" : "sec" }>Personal Details</div>
                            <div onClick={ () => pageChangeClick( 2 ) } className={ currentComponent == 2 ? "sec active" : "sec" }>Member's Ticket</div>
                            <div onClick={ () => pageChangeClick( 3 ) } className={ currentComponent == 3 ? "sec active" : "sec" }>General Ticket</div>
                            {
                                dropdowns.membershipTicket != "" && (
                                    <div onClick={ () => pageChangeClick( 4 ) } className={ currentComponent == 4 ? "sec active" : "sec" }>Number Verification</div>
                                )
                            }
                        </div>
                        {
                            renderCurrentComponent()
                        }
                    </div>



                    <div className="button but">
                        { currentComponent !== 1 && (
                            < button className='prev' onClick={ prevClicked }>
                                Previous
                            </button>
                        ) }

                        { currentComponent !== 5 && (
                            <button className='next' onClick={ nextClicked }>
                                Next
                            </button>
                        ) }


                    </div>
                    <div style={ { marginBottom: '10px' } } ></div>

                    <center>
                        <div className="image-container">
                            <a href="https://app.thewaapi.com" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={ exampleImage }
                                    alt="Example"
                                    style={ imageStyle }  // Apply the dynamic style
                                />
                            </a>
                        </div>
                    </center>
                </div>
            </div>

            <footer className='footer'>
                <div className='footer-content'>
                    <ul>
                        <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                        <li><a href="/terms-of-service.html">Terms of Service</a></li>
                        <li><a href="/refund-policy.html">Refund Policy</a></li>
                        <li><a href="/shipping-policy.html">Shipping Policy</a></li>
                    </ul>
                    <p>&copy; 2024 Mariners Arena. All rights reserved.</p>
                    <p>Developed and Designed by <a href="https://www.betazeninfotech.com" target="_blank">BetaZen InfoTech</a></p>

                </div>
            </footer>

        </section>
    );
};

export default InternForm;

