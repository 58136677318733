import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppStateContext } from '../Reducers/AppStateContext';

import "react-country-state-city/dist/react-country-state-city.css";
import TextInput from './TextInput';

const Step2 = () =>
{

    const {
        dropdowns, setDropdownOption
    } = useAppStateContext();

    const handleDropdownChange = ( dropdownName, e ) =>
    {
        setDropdownOption( dropdownName, e.target.value );
    };


    return (

        <div className="right">
            <form className='main-scroll'>

                <div className="info-box">
                    <label for="lang">Select Ticket: *</label>
                    <select value={ dropdowns.membershipTicket } onChange={ ( e ) => handleDropdownChange( 'membershipTicket', e ) }>
                        <option value="">Select Ticket</option>
                        <option value="C1">C1 - Rs 100.00</option>
                        <option value="C2">C2 - Rs 150.00</option>
                        <option value="C3">C3 - Rs 100.00</option>
                    </select>
                </div>


                <div style={ { marginBottom: '10px' } } ></div>

            </form>
        </div>
        // </div>
    );
};

export default Step2;
