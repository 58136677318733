import React, { useState, useEffect } from 'react';
import { useCommonContext } from '../Contexts/CommonContext';
import { useAppStateContext } from './Reducers/AppStateContext';
import axios from 'axios';
import shortUUID from 'short-uuid';




const Payments = () =>
{

    const { state } = useCommonContext();
    const { dropdowns } = useAppStateContext();


    // Handler for sending OTP the first time
    const handleSendOTPClick = ( e ) =>
    {
        e.preventDefault(); // Prevent form submission

        const paymentId = shortUUID.generate();
        const totalPrice = ( dropdowns.membershipTicket == 'C1' ? 100 :
            dropdowns.membershipTicket == 'C2' ? 150 :
                dropdowns.membershipTicket == 'C3' ? 100 :
                    0 ) +
            ( parseInt( dropdowns.generalC1Ticket ) * 200 ) +
            ( parseInt( dropdowns.generalC2Ticket ) * 400 ) +
            ( parseInt( dropdowns.generalC3Ticket ) * 200 );

        if ( totalPrice == 0 )
        {
            return alert( 'Please select at least one ticket to proceed.' );
        }
        // axios call to send OTP 
        axios.post( 'https://backend.members.marinersarena.com/payment-link-creation',
            {
                matchId: "455jj",

                name: state.name,
                whatsappNo: state.whatsappNo,
                membershipId: state.membershipId,

                membershipTicket: dropdowns.membershipTicket,
                // membershipTicketPrice: String,

                generalC1Ticket: dropdowns.generalC1Ticket,
                // generalC1TicketPrice: String,

                generalC2Ticket: dropdowns.generalC2Ticket,
                // generalC2TicketPrice: String,

                generalC3Ticket: dropdowns.generalC3Ticket,
                // generalC3TicketPrice: String,


                totalPrice: totalPrice.toString(),
                paymentId,

                OTP: state.OTP,

            }
        )
            .then( ( response ) =>
            {
                console.log( response.data );
                const resData = response.data;
                if ( resData.status === 'success' )
                {
                    // alert( 'OTP has been sent to your WhatsApp number.' );
                    // setTimer( 5 );
                    // setHasSentOTP( true );
                    // setShowResendButton( false );

                    alert( 'Redirect to payment link...' );
                    // setTimer( 5 ); // Restart timer
                    // setShowResendButton( false ); // Hide the resend button again
                } else
                {
                    alert( resData.status );
                }

            } )
            .catch( ( error ) => console.error( error ) );












        // const userConfirmed = window.confirm( 'Do you complete payment?' );
        // if ( userConfirmed )
        // {
        //     alert( 'Your ticket booking is successful.' );
        // } else
        // {
        //     alert( 'Payment not complete.' );
        // }
    };



    return (
        <div className="right">
            <form className='main-scroll'>
                <div className="info-box">
                    <label>Order details:</label>
                    <span><b>Reg WhatsApp No: </b>{ state.whatsappNo && `+91-${ state.whatsappNo }` }</span>
                    <span><b>OTP: </b>{ state.otp && `${ state.otp }` }</span>
                    <div style={ { marginBottom: '10px' } } ></div>

                    <span><b>Membership Ticket: </b>{
                        dropdowns.membershipTicket &&
                        `${ dropdowns.membershipTicket } (Rs: ${ dropdowns.membershipTicket === 'C1' ? 100 : dropdowns.membershipTicket === 'C2' ? 150 : dropdowns.membershipTicket === 'C3' ? 100 : 0 }.00)`
                    } </span>
                    <div style={ { marginBottom: '10px' } } ></div>

                    <span><b>C1 Ticket: </b>{
                        dropdowns.generalC1Ticket &&
                        `${ dropdowns.generalC1Ticket } (Total Rs: ${ parseInt( dropdowns.generalC1Ticket ) * 200 }.00)`
                    }</span>
                    <span><b>C2 Ticket: </b>{
                        dropdowns.generalC2Ticket &&
                        `${ dropdowns.generalC2Ticket } (Total Rs: ${ parseInt( dropdowns.generalC2Ticket ) * 400 }.00)`
                    }</span>
                    <span><b>C3 Ticket: </b>{
                        dropdowns.generalC3Ticket &&
                        `${ dropdowns.generalC3Ticket } (Total Rs: ${ parseInt( dropdowns.generalC3Ticket ) * 200 }.00)`
                    }</span>


                    <div style={ { marginBottom: '10px' } } ></div>
                    <span><b>Total payable amount: </b>Rs. <b>{
                        ( dropdowns.membershipTicket == 'C1' ? 100 :
                            dropdowns.membershipTicket == 'C2' ? 150 :
                                dropdowns.membershipTicket == 'C3' ? 100 :
                                    0 ) +
                        ( parseInt( dropdowns.generalC1Ticket ) * 200 ) +
                        ( parseInt( dropdowns.generalC2Ticket ) * 400 ) +
                        ( parseInt( dropdowns.generalC3Ticket ) * 200 )
                    }.00</b></span>

                </div>

                <div style={ { marginBottom: '10px' } } ></div>

                <div className='button'>
                    <button type="button" onClick={ handleSendOTPClick }>
                        Complete Payment
                    </button>
                </div>

                <div style={ { marginBottom: '10px' } } ></div>

            </form>
        </div>
    );
};

export default Payments;
