import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppStateContext } from '../Reducers/AppStateContext';

import "react-country-state-city/dist/react-country-state-city.css";
import TextInput from './TextInput';

const Step3 = () =>
{

    const {
        dropdowns, setDropdownOption
    } = useAppStateContext();

    const handleDropdownChange = ( dropdownName, e ) =>
    {
        setDropdownOption( dropdownName, e.target.value );
    };


    return (

        <div className="right">
            <form className='main-scroll'>

                <div className="info-box">
                    <label for="lang">Select C1 Ticket (Rs 200.00 / ticket) : *</label>
                    <select value={ dropdowns.generalC1Ticket } onChange={ ( e ) => handleDropdownChange( 'generalC1Ticket', e ) }>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                    </select>
                </div>

                <div className="info-box">
                    <label for="lang">Select C2 Ticket (Rs 400.00 / ticket) : *</label>
                    <select value={ dropdowns.generalC2Ticket } onChange={ ( e ) => handleDropdownChange( 'generalC2Ticket', e ) }>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                    </select>
                </div>

                <div className="info-box">
                    <label for="lang">Select C3 Ticket (Rs 200.00 / ticket) : *</label>
                    <select value={ dropdowns.generalC3Ticket } onChange={ ( e ) => handleDropdownChange( 'generalC3Ticket', e ) }>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                    </select>
                </div>


                <div style={ { marginBottom: '10px' } } ></div>

            </form>
        </div>
        // </div>
    );
};

export default Step3;
