import React, { useState, useEffect } from 'react';
import TextInput from './TextInput';
import axios from 'axios';
import { useCommonContext } from '../../Contexts/CommonContext';
import { useAppStateContext } from '../Reducers/AppStateContext';

const Step4 = () =>
{
    const { state } = useCommonContext();
    const { dropdowns } = useAppStateContext();

    const [ timer, setTimer ] = useState( 0 ); // Timer starts at 0, no countdown initially
    const [ hasSentOTP, setHasSentOTP ] = useState( false ); // To track whether OTP has been sent
    const [ showResendButton, setShowResendButton ] = useState( false ); // Control Resend button visibility

    // Timer countdown effect
    useEffect( () =>
    {
        let countdown;
        if ( timer > 0 )
        {
            countdown = setInterval( () =>
            {
                setTimer( ( prevTimer ) => prevTimer - 1 );
            }, 1000 );
        } else if ( timer === 0 && hasSentOTP )
        {
            setShowResendButton( true ); // Show resend button when timer ends
        }

        return () => clearInterval( countdown ); // Clean up interval on unmount
    }, [ timer, hasSentOTP ] );

    // Handler for sending OTP the first time
    const handleSendOTPClick = ( e ) =>
    {
        e.preventDefault(); // Prevent form submission
        console.log( state );
        console.log( state.whatsappNo );

        const userConfirmed = window.confirm( 'Do you want to send the OTP now?' );
        if ( userConfirmed )
        {
            // axios call to send OTP
            axios.post( 'https://backend.members.marinersarena.com/send-otp', { membershipTicket: dropdowns.membershipTicket, matchId: "455jj", whatsappNo: state.whatsappNo } )
                .then( ( response ) =>
                {
                    console.log( response.data );
                    const resData = response.data;
                    if ( resData.status === 'success' )
                    {
                        alert( 'OTP has been sent to your WhatsApp number.' );
                        setTimer( 5 );
                        setHasSentOTP( true );
                        setShowResendButton( false );
                    } else
                    {
                        alert( resData.status );
                    }

                } )
                .catch( ( error ) => console.error( error ) );


        }
    };

    // Handler for resending OTP
    const handleResendOTPClick = () =>
    {
        // axios call to send OTP payment-link-creation
        axios.post( 'https://backend.members.marinersarena.com/resend-otp', { membershipTicket: dropdowns.membershipTicket, matchId: "455jj", whatsappNo: state.whatsappNo } )
            .then( ( response ) =>
            {
                console.log( response.data );
                const resData = response.data;
                if ( resData.status === 'success' )
                {
                    // alert( 'OTP has been sent to your WhatsApp number.' );
                    // setTimer( 5 );
                    // setHasSentOTP( true );
                    // setShowResendButton( false );

                    alert( 'OTP has been resent to your WhatsApp number.' );
                    setTimer( 5 ); // Restart timer
                    setShowResendButton( false ); // Hide the resend button again
                } else
                {
                    alert( 'OTP has not been sent' );
                }

            } )
            .catch( ( error ) => console.error( error ) );


    };

    return (
        <div className="right">
            <form className='main-scroll'>
                <div className="info-box">
                    <label>OTP sent to your WhatsApp No:</label>
                    <span>{ state.whatsappNo && `+91-${ state.whatsappNo }` }</span>
                </div>

                <div className='button'>
                    {/* Show Send OTP button only if OTP hasn't been sent yet */ }
                    { !hasSentOTP && (
                        <button type="button" onClick={ handleSendOTPClick }>
                            Send OTP through WhatsApp No
                        </button>
                    ) }

                    {/* Countdown Timer */ }
                    { timer > 0 && (
                        <div className="timer">
                            Resend OTP available in: { Math.floor( timer / 60 ) }:{ String( timer % 60 ).padStart( 2, '0' ) }
                        </div>
                    ) }

                    {/* Show Resend OTP button after the timer reaches 0 */ }
                    { showResendButton && (
                        <button type="button" onClick={ handleResendOTPClick }>
                            Resend OTP
                        </button>
                    ) }
                </div>

                {/* OTP Input Field */ }
                <TextInput type="text" field="OTP" placeholder="Enter your OTP" label="OTP: *" />
                <div style={ { marginBottom: '10px' } } ></div>
            </form>
        </div>
    );
};

export default Step4;
