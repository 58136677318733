import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../../assets/logo.png';
import exampleImage from './../../assets/1940_250.jpg'; // Adjust the path according to your folder structure


const Form = () =>
{
    const navigate = useNavigate();
    const applyClicked = () =>
    {
        navigate( "/form" );
    };

    const [ isMobile, setIsMobile ] = useState( window.innerWidth <= 768 );

    useEffect( () =>
    {
        const handleResize = () =>
        {
            setIsMobile( window.innerWidth <= 768 );
        };

        window.addEventListener( 'resize', handleResize );

        return () => window.removeEventListener( 'resize', handleResize );
    }, [] );

    const imageStyle = {
        width: isMobile ? '100px' : '250px',  // Width based on screen size
        height: isMobile ? '100px' : '250px',  // Height based on screen size
    };

    const imageStyleFooter = {
        width: isMobile ? '266px' : '400px',  // Width based on screen size
        height: isMobile ? '50px' : '75px',  // Height based on screen size
    };

    return (
        <section>
            <div className="container">
                <div className='main'>
                    <div className='heading'>
                        <h1>Mariners Arena - Match Ticket booking</h1>
                    </div>

                    <center>
                        <img
                            src={ logo }
                            alt="Example"
                            style={ imageStyle }  // Apply the dynamic style
                        />
                    </center>

                    <div className='info'>


                        <div className='box'>
                            <h3>🌟 Description 🌟</h3>
                            <p>
                                Are you ready to embark on an incredible journey in the world of application development? Look no further, because BetaZen InfoTech is offering an exciting internship opportunity that will set your career in motion!
                            </p>
                        </div>

                        <div className='box'>
                            <h3>📌 Match Details:</h3>
                            <ul>
                                <li><span>Name:</span> Mohon Bagan</li>
                                <li><span>Duration:</span> 90 min</li>
                                <li><span>Location:</span> Kolkata</li>
                                <li><span>Match Date & Time:</span> Sunday, 8th Sep 2024 04:00 PM</li>
                            </ul>
                        </div>

                        <div className='box'>
                            <h3>💵 Ticket Pricing:</h3>
                            <ul>
                                <li><span>Members C1 Ticket Price:</span> Rs. 100.00</li>
                                <li><span>Members C2 Ticket Price:</span> Rs. 150.00</li>
                                <li><span>Members C3 Ticket Price:</span> Rs. 100.00</li>
                                <li><span>General C1 Ticket Price:</span> Rs. 200.00</li>
                                <li><span>General C1 Ticket Price:</span> Rs. 400.00</li>
                                <li><span>General C1 Ticket Price:</span> Rs. 200.00</li>
                            </ul>
                        </div>
                    </div>

                    <div className='button' onClick={ applyClicked }>
                        <button>
                            Book now
                        </button>
                    </div>
                </div>
            </div>

            <center>
                <div className="image-container">
                    <a href="https://app.thewaapi.com" target="_blank" rel="noopener noreferrer">
                        <img
                            src={ exampleImage }
                            alt="Example"
                            style={ imageStyleFooter }  // Apply the dynamic style
                        />
                    </a>
                </div>
            </center>

            <footer className='footer'>
                <div className='footer-content'>
                    <ul>
                        <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                        <li><a href="/terms-of-service.html">Terms of Service</a></li>
                        <li><a href="/refund-policy.html">Refund Policy</a></li>
                        <li><a href="/shipping-policy.html">Shipping Policy</a></li>
                    </ul>
                    <p>&copy; 2024 Mariners Arena. All rights reserved.</p>
                    <p>Developed and Designed by <a href="https://www.betazeninfotech.com" target="_blank">BetaZen InfoTech</a></p>

                </div>
            </footer>
        </section>
    );
};

export default Form;
