import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from '../../Contexts/CommonContext';
import { useAppStateContext } from '../Reducers/AppStateContext';
import axios from 'axios';
import { v4 as uuid } from "uuid";

const Final = () =>
{
    const navigate = useNavigate();

    const { state } = useCommonContext();

    const prevClicked = () =>
    {

        navigate( '/form' );
    }

    const { dropdowns, appliedForPost, appliedForLanguage, selectedFile } = useAppStateContext();

    const [ submitting, setSubmitting ] = useState( false );

    let date = new Date();

    const unique_id = uuid();

    const renameFile = ( file, newFileName ) =>
    {
        const fileExtension = file.name.split( '.' ).pop();
        return new File( [ file ], `${ newFileName }.${ fileExtension }`, { type: file.type } );
    };

    const handleSubmit = async () =>
    {
        if ( submitting || !selectedFile )
        {
            return;
        }

        setSubmitting( true );





        // check input2 is email or not
        const email = state.input2;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if ( !emailRegex.test( email ) )
        {
            alert( 'Please enter valid email' );
            return;
        }
        // check all input fields are filled or not
        if ( !state.input1 || !state.input2 || !state.input3 || !state.input4 || !state.input5 || !state.input6 || !state.input7 || !state.input8 || !state.input9 || !state.input10 || !state.input11 || !state.input12 || !state.input18 )
        {
            alert( 'Please fill all required the fields' );
            return;
        }

        // check appliedForPost is empty or not
        if ( appliedForPost.length == 0 )
        {
            alert( 'Please select atleast one post' );
            return;
        }

        // check appliedForLanguage is empty or not
        // if ( appliedForLanguage.length == 0 )
        // {
        //     alert( 'Please select atleast one language' );
        //     return;
        // }

        // url pattern
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        // check linkdin is valid or not
        if ( !urlPattern.test( state.input18 ) )
        {
            alert( 'Please enter valid linkdin link' );
            return;
        }
        // check github is valid or not
        if ( state.input19 && !urlPattern.test( state.input19 ) )
        {
            alert( 'Please enter valid github link' );
            return;
        }
        // check drive is valid or not
        if ( state.input20 && !urlPattern.test( state.input20 ) )
        {
            alert( 'Please enter valid drive link' );
            return;
        }

        // phone number validation
        var phoneRegex = /^\++[0-9]+-[0-9]{10}$/;
        // check phone is valid or not
        // const phone = state.input3;
        // if ( !phoneRegex.test( phone ) )
        // {
        //     alert( 'Please enter valid phone number' );
        //     return;
        // }
        // check whatsapp is valid or not
        // const whatsapp = state.input4;
        // if ( !phoneRegex.test( whatsapp ) )
        // {
        //     alert( 'Please enter valid whatsapp number' );
        //     return;
        // }

        try
        {
            const renamedFile = renameFile( selectedFile, unique_id );
            const formBody = {
                uniqueID: unique_id,
                uploadTime: date,
                uploadTimeStamp: date.getTime().toString(),
                personalDetails: {
                    name: state.input1,
                    email: state.input2,
                    phone: state.input3,
                    whatsapp: state.input4,
                },
                addressDetails: {
                    address: state.input5,
                    city: state.input6,
                    state: state.input7,
                    country: state.input8,
                    pin: state.input9,
                },
                educationDetails: {
                    college: state.input10,
                    dept: state.input11,
                    year: state.input12,
                },
                workDetails: {
                    level: dropdowns.dropdown1,
                    appliedForPost: appliedForPost,
                    appliedForLanguage: appliedForLanguage,
                },
                otherDetails: {
                    expectations: state.input14,
                    dailyTime: state.input15,
                    startingDate: state.input16,
                    whyYou: state.input17,
                    linkdin: state.input18,
                    github: state.input19,
                    drive: state.input20,
                    msgForUs: state.input21,
                }
            };


            // File upload
            const formData = new FormData();
            formData.append( 'file', renamedFile );
            // formData.append( 'body', formBody );


            if ( process.env.NODE_ENV != 'development' )
            {
                const myApi = "https://internship-api.betazeninfotech.com/";


                await axios.post( myApi + 'api/upload', formData );
                console.log( 'File uploaded successfully!' );

                // Form data submission
                const response = await axios.post( myApi + 'api/sendData', formBody );

                console.log( response.data );
                console.log( unique_id );

                // windows href go to home page
                window.location.href = '/';
                // window.location.reload();

                // navigate( '/' );
            } else
            {
                const localApi = "https://backend.members.marinersarena.com/";


                await axios.post( localApi + 'api/upload', formData );
                console.log( 'File uploaded successfully!' );

                // Form data submission
                const response = await axios.post( localApi + 'api/sendData', formBody );

                console.log( response.data );
                console.log( unique_id );

                // windows href go to home page
                window.location.href = '/';
                // window.location.reload();

                // navigate( '/' );
            }

        } catch ( error )
        {
            console.error( 'Error uploading file or submitting data:', error );
        } finally
        {
            setSubmitting( false );
        }
    };

    return (
        <section>
            <div className="container" style={ { height: '100vh' } }>
                <div className="main">
                    <div className="ans final">
                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Resume Details</div>
                            </div>

                            <div className='ans-boxes'>
                                <div className="info1">
                                    <div className="field">Uploaded CV / Resume:</div>
                                    { selectedFile ? <div className="data">{ selectedFile.name }</div>
                                        :
                                        <div className="data void">No file choosen!</div> }
                                </div>
                            </div>
                        </div>

                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Personal details</div>

                            </div>

                            <div className='ans-boxes'>
                                <div className="info1">
                                    <div className="field">Name:</div>
                                    {
                                        state.input1 ?
                                            <div className="data">{ state.input1 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">Email:</div>
                                    {
                                        state.input2 ?
                                            <div className="data">{ state.input2 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">Phone Number:</div>
                                    {
                                        state.input3 ?
                                            <div className="data">{ state.input3 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">Whatsapp Number:</div>
                                    {
                                        state.input4 ?
                                            <div className="data">{ state.input4 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Address details</div>
                            </div>

                            <div className='ans-boxes'>
                                <div className="info1">
                                    <div className="field">Address:</div>
                                    {
                                        state.input6 ?
                                            <div className="data">{ state.input6 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">State:</div>
                                    {
                                        state.input7 ?
                                            <div className="data">{ state.input7 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">Country:</div>
                                    {
                                        state.input8 ?
                                            <div className="data">{ state.input8 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                                <div className="info1">
                                    <div className="field">Postal Code:</div>
                                    {
                                        state.input9 ?
                                            <div className="data">{ state.input9 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Educational Details</div>
                            </div>
                            <div className='ans-boxes'>
                                <div className="info1">
                                    <div className="field">College Name:</div>
                                    {
                                        state.input10 ?
                                            <div className="data">{ state.input10 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1">
                                    <div className="field">Department:</div>
                                    {
                                        state.input11 ?
                                            <div className="data">{ state.input11 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1">
                                    <div className="field">Study year:</div>
                                    {
                                        state.input12 ?
                                            <div className="data">{ state.input12 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Work Experience Details</div>
                            </div>

                            <div className="ans-boxes">

                                <div className="info1">
                                    <div className="field">Preferred Coding Language:</div>
                                    <div className="data">
                                        {/* set loop using appliedForLanguage */ }
                                        { appliedForLanguage.map( ( item ) => (
                                            <div>
                                                <div className='mb-2'>{ " - " }{ item }</div>
                                            </div>
                                        ) ) }
                                    </div>
                                </div>

                                <div className="info1">
                                    <div className="field">Strength of your choosen language:</div>
                                    <div className="data">
                                        { dropdowns.dropdown1 == 'Advanced' ? "Advanced" : ( dropdowns.dropdown1 == 'Intermediate' ? "Intermediate" : "Beginner" ) }
                                    </div>
                                </div>

                                <div className="info1  colans">
                                    <div className="field">Applied for:</div>
                                    <div className="data">
                                        {/* set loop using appliedForPost */ }
                                        { appliedForPost.map( ( item ) => (
                                            <div>
                                                <div className='mb-2'>{ " - " }{ item }</div>
                                            </div>
                                        ) ) }
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='box-info'>
                            <div className="info-head">
                                <div className="head-field">Other Details</div>
                            </div>

                            <div className="ans-boxes">
                                <div className="info1 colans">
                                    <div className="field">What are your expectations from this internship?</div>
                                    {
                                        state.input14 ?
                                            <div className="data">{ state.input14 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 rows">
                                    <div className="field">How much time can you devote for this internship on daily basis?</div>
                                    {
                                        state.input15 ?
                                            <div className="data">{ state.input15 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 rows">
                                    <div className="field">If selected, when are you available to start/join?</div>
                                    {
                                        state.input16 ?
                                            <div className="data">{ state.input16 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 colans">
                                    <div className="field">Why should we select you?</div>
                                    {
                                        state.input17 ?
                                            <div className="data">{ state.input17 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 rows">
                                    <div className="field">Linkedin Profile:</div>
                                    {
                                        state.input18 ?
                                            <div className="data">{ state.input18 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 rows">
                                    <div className="field">GitHub Profile:</div>
                                    {
                                        state.input19 ?
                                            <div className="data">{ state.input19 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 rows">
                                    <div className="field">Drive link:</div>
                                    {
                                        state.input20 ?
                                            <div className="data">{ state.input20 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>

                                <div className="info1 colans">
                                    <div className="field">Any Message For Us:</div>
                                    {
                                        state.input21 ?
                                            <div className="data">{ state.input21 }</div> :
                                            <div className="data void">Field is empty!</div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="button but">
                        <button className='prev' onClick={ prevClicked }>
                            Edit Info
                        </button>

                        <button className='next' style={ { background: '#008631' } } onClick={ handleSubmit }>
                            Submit
                        </button>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Final;
